import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'


const Header = ({ onSave, info={ title: "" , description: "" }}) => {

    return (
        <header className="palette-collection-header">
            <div style={{ marginBottom: "1rem" }}>
                <div className="icon clickable-icon" >
                    <Link to="/palette-collection">
                        <div className="link-button">
                            <FaArrowLeft/> <div style={{ margin: "auto", padding: "0rem 0.5rem", whiteSpace:"nowrap", fontWeight:"bold" }}>Color Palettes</div>
                        </div>
                    </Link>
                </div>
            </div>
            <h2><input name="title-info" defaultValue={info.title} placeholder="Color palette name" onBlur={onSave}/></h2>
            <h3><input name="description-info" defaultValue={info.description} placeholder="Color palette description" onBlur={onSave}/></h3>
        </header>
    )
}

export default Header
