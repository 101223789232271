import Palette from './scenes/Palette'
import PaletteCollection from './scenes/PaletteCollection'
//import ROUTES, { RenderRoutes } from "./routes";
import { Route, Switch, useParams } from 'react-router-dom'

function App() {

  const databaseEnabled = false 

  return (
    <div className="App card">
      <Switch>
          <Route path="/palette-collection" exact >
            <PaletteCollection databaseEnabled={databaseEnabled}/> 
          </Route>
          
          <Route path="/palette-collection/palettes/:id" children={<Child databaseEnabled={databaseEnabled} />}/>
            {/* <ColorScheme colorSchemeId={activeColorSchemeId}/>  */}

        </Switch>
      
      {/* <RenderRoutes routes={ROUTES} /> */}
    </div>
  );
}

export default App;

function Child({ databaseEnabled }) {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams();

  return (
    <Palette paletteId={id} databaseEnabled={databaseEnabled} />
  )
}