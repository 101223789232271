import ColorRectangle from '../../../components/ColorRectangle'
import { FaTrash, FaPen, FaClone, FaGripLines } from 'react-icons/fa'
import ColorSelector from '../components/ColorSelector'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function Colors({ colors, activeColorSelector, setActiveColorSelector, onDelete, onUpdate, onClone, handleOnDragEnd }) {


    return (
        <DragDropContext onDragEnd={handleOnDragEnd}> 
            <Droppable droppableId="color-list">
                {(provided) => (
                    <div className="color-list" {...provided.droppableProps} ref={provided.innerRef}>
                        {colors.map((color, index) => (
                            <Draggable key={color.id} draggableId={color.id} index={index} >
                                {(provided) => (
                                    <div className="draggable" ref={provided.innerRef} {...provided.draggableProps} >
                                        <div className="box-wrapper card" >
                                            <div className="color-header">
                                                <div className="color-box-icons left-icons" > 
                                                    <div className="small-icon-btn icon" {...provided.dragHandleProps}>         
                                                        <FaGripLines />
                                                    </div>
                                                </div>
                                                <div className="color-box-icons right-icons">          
                                                    <FaPen className="small-icon-btn icon clickable-icon" onClick={() => {setActiveColorSelector(activeColorSelector === color.id ? null : color.id)}}/>
                                                    <FaClone className="small-icon-btn icon clickable-icon" onClick={() => {onClone(color.id)}}/>
                                                    <FaTrash className="small-icon-btn icon clickable-icon delete-icon" onClick={() => {onDelete(color.id)}}/>
                                                </div>
                                            </div>
                                            <div className="color-box-wrapper">
                                                {activeColorSelector === color.id ? 
                                                    <ColorSelector color={color} onUpdate={onUpdate} setActiveColorSelector={setActiveColorSelector} /> :
                                                    <ColorRectangle color={color.hex} />}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        )
    }



export default Colors
