function reduce([numerator,denominator]){
  let gcd = function gcd(a,b){
    return b ? gcd(b, a%b) : a;
  };
  gcd = gcd(numerator,denominator);
  return [numerator/gcd, denominator/gcd];
}

const SaveItemOrder = ({ items, updatedIndex }) => {
    const leftOrder = ((updatedIndex-1) > -1) ? (items[updatedIndex-1].sort_order) : [0, 1]
    const rightOrder = ((updatedIndex+1) < items.length) ? (items[updatedIndex+1].sort_order) : [1, 0]
    
    //console.log('left', leftOrder)
    //console.log('right', rightOrder)
    items[updatedIndex].sort_order = reduce([leftOrder[0]+rightOrder[0], leftOrder[1]+rightOrder[1]])
    return items
}

export default SaveItemOrder