
const url = `http://localhost:5000/palettes`

const fetchPalettes = async () => {
    const res = await fetch(`${url}`)
    const data = await res.json()
    return data
}

const putPalette = (newPalette) => {
    fetch(`${url}`, {
        method: "PUT", 
        headers: {
        'Content-type': 'application/json'
        },
        body: JSON.stringify(newPalette)
    })
}

const deletePalette = (deletePalette) => {

    fetch(url, {
        method: "DELETE", 
        headers: {
        'Content-type': 'application/json'
        },
        body: JSON.stringify(deletePalette)
    })
}

const patchPalette = (updatedPalette) => {

    fetch(url, {
        method: "PATCH", 
        headers: {
        'Content-type': 'application/json'
        },
        body: JSON.stringify(updatedPalette)
    })
}

  
export { fetchPalettes, putPalette, deletePalette, patchPalette }