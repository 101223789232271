import Header from './scenes/Header'
import Palettes from './scenes/Palettes'
import saveItemOrder from '../../services/SaveItemOrder'
import AddBox from './scenes/AddBox'
import { withRouter } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { v4 as UUID } from 'uuid'
import { fetchPalettes as fetchPalettesLocal, putPalette as putPaletteLocal, deletePalette as deletePaletteLocal, patchPalette as patchPaletteLocal } from './services/StoreLocal'
import { fetchPalettes as fetchPalettesHTTP, putPalette as putPaletteHTTP, deletePalette as deletePaletteHTTP, patchPalette as patchPaletteHTTP } from './services/RequestHTTP'

const PaletteCollection = ({ history, databaseEnabled }) => {

  let fetchPalettes = fetchPalettesLocal
  let putPalette = putPaletteLocal
  let deletePalette = deletePaletteLocal
  let patchPalette = patchPaletteLocal

  if (databaseEnabled) { 
    fetchPalettes = fetchPalettesHTTP
    putPalette = putPaletteHTTP
    deletePalette = deletePaletteHTTP
    patchPalette = patchPaletteHTTP
  } 
  
  const [palettes, setPalettes] = useState([])
  
  const newPaletteId = UUID()
  
  useEffect(() => {
    
    const getPalettes = async () => {
      const rawPaletteData = await fetchPalettes()
      const paletteData = rawPaletteData.filter(item => (item.id != null && item.sort_order != null))
      //console.log(paletteData)
      paletteData.sort((a, b) => (a.sort_order[0]/a.sort_order[1] - b.sort_order[0]/b.sort_order[1]))
      setPalettes(paletteData)
    }
    getPalettes()
  }, [])
  

    const onCreateNewPalette = async() => {
      const newPaletteId = UUID()
      const newPaletteTitle = ""
      const newPaletteDescription = ""
      const newColors = []
      const newSortOrder = []

      const newPalette = { id: newPaletteId, title: newPaletteTitle, description: newPaletteDescription, colors: newColors, sort_order: newSortOrder }
      const newPalettes = saveItemOrder({ items: [newPalette, ...palettes], updatedIndex: 0 })
      setPalettes(newPalettes)
      await putPalette(newPalette)

      return history.push(`/palette-collection/palettes/${newPaletteId}`)
    }
    
    const clonePalette = (originalId) => {
      const originalIndex = palettes.findIndex((palette) => palette.id === originalId)
      
      const cloneId = UUID()
      const cloneTitle = `Copy of ${palettes[originalIndex].title}`
      const cloneDescription = palettes[originalIndex].description
      const cloneColors  = palettes[originalIndex].colors
      const cloneSortOrder = []

      const newPalette = { id: cloneId, title: cloneTitle, description: cloneDescription, colors: cloneColors, sort_order: cloneSortOrder }
      let newPalettes = (
      [
        ...palettes.slice(0, originalIndex),
        newPalette,
        ...palettes.slice(originalIndex)
      ]);
      
      newPalettes = saveItemOrder({ items: newPalettes, updatedIndex: newPalettes.findIndex((palette) => (palette.id === cloneId)) })
      
      setPalettes(newPalettes)
  
      putPalette(newPalette)
    }

    const onDeletePalette = async (id) => {
      const paletteToDelete = palettes.find((palette) => palette.id === id)
      const newPalettes = palettes.filter((palette) => palette.id !== id)
      setPalettes(newPalettes)
      await deletePalette(paletteToDelete)
    }

    const handleOnDragEnd = (result) => {
      if (!result.destination) return;
      const items = Array.from(palettes);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
  
      const newPalettes = saveItemOrder({ items: items, updatedIndex: result.destination.index })
      setPalettes(newPalettes)
      console.log(newPalettes[result.destination.index])
      patchPalette(newPalettes[result.destination.index])
    }

    return (
        <div>
            { /* <Header /> */ }
            <AddBox newPaletteId={newPaletteId} onCreate={onCreateNewPalette} />
            { palettes.length > 0 ? 
            <Palettes palettes={palettes} handleOnDragEnd={handleOnDragEnd} onClone={clonePalette} onDelete={onDeletePalette} /> :
            <div className="no-colors color-box-wrapper"><div><center>No color schemes to show<br></br>Press + to get started</center></div></div>
            }
        </div>
    )
}

export default withRouter(PaletteCollection)
