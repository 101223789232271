const fetchPalettes = async () => {

    const data = []

    const keys = Object.keys(localStorage)
    keys.forEach((key) => {
        const value = JSON.parse(localStorage.getItem(key))
        data.push(value)
    })

    return data
}

const putPalette = (newPalette) => {
    localStorage.setItem(newPalette.id, JSON.stringify(newPalette))
}

const deletePalette = (deletePalette) => {
    localStorage.removeItem(deletePalette.id)
}

const patchPalette = (updatedPalette) => {
    localStorage.setItem(updatedPalette.id, JSON.stringify(updatedPalette))
}

  
export { fetchPalettes, putPalette, deletePalette, patchPalette }