import React from 'react'

const ColorRectangle = ({ color }) => {

    return (
        
        <div className="color-box" 
        style={{backgroundColor: color}}>
        </div>
    )
}

export default ColorRectangle
