import { FaTrash, FaClone, FaGripLines } from 'react-icons/fa'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ColorRectangle from '../../../components/ColorRectangle'
import { Link } from 'react-router-dom'

function Palettes({ palettes, onDelete, onClone, handleOnDragEnd }) {


    return (
        <DragDropContext onDragEnd={handleOnDragEnd}> 
            <Droppable droppableId="list">
                {(provided) => (
                    <div className="list" {...provided.droppableProps} ref={provided.innerRef}>
                        {palettes.map((palette, index) => (
                            <Draggable key={palette.id} draggableId={palette.id} index={index} >
                                {(provided) => (
                                    <div className="draggable" ref={provided.innerRef} {...provided.draggableProps} >
                                        <div className="box-wrapper card" >
                                            <div className="color-header">
                                                <div className="color-box-icons left-icons" > 
                                                    <div className="small-icon-btn icon" {...provided.dragHandleProps}>         
                                                        <FaGripLines />
                                                    </div>
                                                </div>
                                                <div className="color-box-icons right-icons">
                                                    <FaClone className="small-icon-btn icon clickable-icon" onClick={() => {onClone(palette.id)}} />
                                                    <FaTrash className="small-icon-btn icon clickable-icon delete-icon" onClick={() => {onDelete(palette.id)}}/>
                                                </div>
                                            </div>
                                            <Link to={`/palette-collection/palettes/${palette.id}`}>
                                                <div className="color-box-wrapper">
                                                    <h3>{palette.title ? palette.title : "Untitled"}</h3>
                                                    <div style={{ marginTop: "0.5rem" }}>
                                                    { palette.colors &&
                                                    palette.colors.map((color, index) => (
                                                        <ColorRectangle key={index} color={color} />
                                                    )) }
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        )
    }



export default Palettes
