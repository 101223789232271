

const url = `http://localhost:5000/palettes`

const fetchColors = async (paletteId) => {
    const res = await fetch(`${url}/${paletteId}`)
    const data = await res.json()
    return data

}

const patchColors = ({ id, title, description }, colors) => {
    let JSONColors = {}
    JSONColors.id = id
    JSONColors.title = title
    JSONColors.description = description
    JSONColors.colors = colors.map((color) => (color.hex))

    fetch(`${url}/${id}`, {
    method: "PATCH", 
    headers: {
        'Content-type': 'application/json'
    },
    body: JSON.stringify(JSONColors)
    })
}

export { fetchColors, patchColors }