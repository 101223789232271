function Button({width, color, text=null, icon=null, onClick}) {

    return (
        <button className="btn" onClick={onClick}
        style={{ backgroundColor: color, width: width }}>
            {icon !== null && icon}
            <br></br>
            {text !== null && text}
        </button>
    )
}

Button.defaultProps = {
    color: "gray"
}

export default Button
