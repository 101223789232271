import ColorSelector from '../components/ColorSelector'
import { FaPlus, FaTimes } from 'react-icons/fa'

const AddBox = ({ activeColorSelector, setActiveColorSelector, saveColor }) => {
    return (
        <div className="add-box">
            
            <div className="color-box-wrapper card">
                <div className="color-header">
                    <div className="color-box-icons left-icons">          
                    </div>
                    <div className="color-box-icons right-icons">
                        {activeColorSelector === "add" ?
                        <FaTimes className="small-icon-btn icon clickable-icon" onClick={() => {setActiveColorSelector(null)}} /> :
                        <FaPlus className="small-icon-btn icon clickable-icon" onClick={() => {setActiveColorSelector("add")}} /> }
                    </div>
                </div>
                {activeColorSelector === "add" && 
                <div className="color-box-wrapper" >
                    <ColorSelector onSave={saveColor} setActiveColorSelector={setActiveColorSelector} /> 
                </div>}
            </div> 
        </div>
    )
}

export default AddBox
