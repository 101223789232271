const fetchColors = async (paletteId) => {
    const data = await JSON.parse(localStorage.getItem(paletteId))
    return data
}

const patchColors = async ({ id, title, description }, colors) => {
    let JSONColors = {}
    JSONColors.id = id
    JSONColors.title = title
    JSONColors.description = description
    JSONColors.sort_order = await JSON.parse(localStorage.getItem(id)).sort_order
    JSONColors.colors = colors.map((color) => (color.hex))

    localStorage.setItem(id, JSON.stringify(JSONColors))
}

export { fetchColors, patchColors }