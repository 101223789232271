import ColorPicker, { useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import Button from "../../../components/Button"
import { useResizeDetector } from 'react-resize-detector';
import { useEffect } from 'react'
import ColorRectangle from '../../../components/ColorRectangle'
import { FaSave, FaTimes } from 'react-icons/fa'


function ColorSelector({ color={ id: "add", hex:"#999999" }, onSave=null, onUpdate=null, setActiveColorSelector=null }) {

  const { width : parentWidth, ref : parentDiv } = useResizeDetector();
  //const [colorPickerWidth, setColorPickerWidth] = useState(null);
  //const parentRef = useRef(null);
  
  useEffect( () => {
    document.querySelector(".rcp").style.display = "block";
  });

  const [selectedColor, setSelectedColor] = useColor("hex", color.hex);

  return (
          <div>
            <ColorRectangle color={selectedColor.hex} />
              <h4>Color Selector</h4>
              <div className="color-picker" ref={parentDiv}>
                <ColorPicker width={isNaN(parentWidth) ? 0 : parentWidth} height={125} color={selectedColor} onChange={setSelectedColor} />
              </div>
            {(onSave !== null) && 
              <div className="wide-btn">
                <Button width="100%" color="#1a8917" icon={<FaSave className="button-icon-btn btn-icon" />} onClick={() => {onSave(selectedColor)}} />
              </div>
            }
            
            {(onUpdate !== null && setActiveColorSelector !== null) && 
              <div className="wide-btn">
                <Button width="50%" color="#1a8917" icon={<FaSave className="button-icon-btn btn-icon" />} text="SAVE COLOR" onClick={() => {onUpdate(color.id, selectedColor)}} />
                <Button width="50%" color="#888888" icon={<FaTimes className="button-icon-btn btn-icon" />} text="CANCEL" onClick={() => {setActiveColorSelector(null)}} />
              </div>
            }
          </div>
  )
}


export default ColorSelector
