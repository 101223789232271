import Header from './scenes/Header'
import Colors from './scenes/Colors'
import AddBox from './scenes/AddBox'
import { useState, useEffect } from 'react'
import { v4 as UUID } from 'uuid'
import { fetchColors as fetchColorsLocal, patchColors as patchColorsLocal } from './services/StoreLocal'
import { fetchColors as fetchColorsHTTP, patchColors as patchColorsHTTP } from './services/RequestHTTP'


const Palette = ({ databaseEnabled, paletteId }) => {
      
  let fetchColors = fetchColorsLocal
  let patchColors = patchColorsLocal

  if (databaseEnabled) { 
    fetchColors = fetchColorsHTTP
    patchColors = patchColorsHTTP
  } 
    
  const [info, setInfo] = useState({ id:"", title: "", description: "" })
  const [activeColorSelector, setActiveColorSelector] = useState(null) // "add" for add or id
  const [colors, setColors] = useState([])

  useEffect(() => {
    const getColors = async () => {
      let { id:fetchedId, title:fetchedTitle, description:fetchedDescription, colors:fetchedColors } = await fetchColors(paletteId)
      const fetchedInfo = { id: fetchedId, title: fetchedTitle, description: fetchedDescription }
      
      setInfo(fetchedInfo)
      
      setColors(fetchedColors.map((color) => ({ id: UUID(), hex: color }))) // Assign temp id for drag and drop
    }
    getColors()
  }, [])



  const onInfoUpdate = (e) => {
    const name = e.target.name
    const value = e.target.value
    let newInfo = {}
    if (name === "title-info"){
      newInfo = { id: info.id, title: value, description: info.description }
    } else if (name === "description-info") {
      newInfo = { id: info.id, title: info.title, description: value }
    } else {
      newInfo = info
    }
    setInfo(newInfo)
    patchColors(newInfo, colors)
  }


  const deleteColor = async (id) => {
    const newColors = colors.filter((color) => color.id !== id)
    
    setColors(newColors)
    setActiveColorSelector(null)
    
    patchColors(info, newColors)
  }

  const addColor = async (selectedColor) => {
    const newColor = { id: UUID(), hex: selectedColor.hex, order: 0 }
    const newColors = [newColor, ...colors]
    
    setColors(newColors)
    setActiveColorSelector(null)
    patchColors(info, newColors)
  }
  
  const updateColor = (id, selectedColor) => {
    const newColors = colors.map((color) => color.id !== id ? color : { id: color.id, hex: selectedColor.hex, order: color.order }) 
    setColors(newColors)
    setActiveColorSelector(null)
    patchColors(info, newColors)
  }

  const cloneColor = (originalId) => {
    const originalIndex = colors.findIndex((color) => color.id === originalId)
    
    const cloneId = UUID()
    const cloneHex = colors[originalIndex].hex;
    const newColor = { id: cloneId, hex: cloneHex};
    let newColors = (
    [
      ...colors.slice(0, originalIndex),
      newColor,
      ...colors.slice(originalIndex)
    ]);

    setColors(newColors)

    patchColors(info, newColors)
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(colors);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setColors(items)
    patchColors(info, items)
  }


  return (
      <div>
          <Header info={info} onSave={onInfoUpdate} />
          <AddBox activeColorSelector={activeColorSelector} setActiveColorSelector={setActiveColorSelector} saveColor={addColor}/>
          { colors.length > 0 ? 
          <Colors colors={colors} activeColorSelector={activeColorSelector} setActiveColorSelector={setActiveColorSelector} onDelete={deleteColor} onUpdate={updateColor} onClone={cloneColor} handleOnDragEnd={handleOnDragEnd} /> :
          <div className="no-colors color-box-wrapper"><div><center>No colors to show<br></br>Press + to get started</center></div></div>
          }
      </div>  )
}

export default Palette
