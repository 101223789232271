import { FaPlus } from 'react-icons/fa'

const AddBox = ({ newPaletteId, onCreate }) => {
    return (
        <div className="add-box">
            
            <div className="color-box-wrapper card">
                <div className="color-header">
                    <div className="color-box-icons left-icons">          
                    </div>
                    <div className="color-box-icons right-icons">
                        <FaPlus className="small-icon-btn icon clickable-icon" onClick={()=>onCreate(newPaletteId)}/> 
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default AddBox
